import React from "react"

const Breadcrumb = ({ paths }) => (
  <>
    <div className="row column mt0 mb6">
      <ul className="breadcrumbs">
        <li>
          <a href="/" key={""}>
            <em className="icon-home mr2"></em> Home
          </a>
        </li>


        {paths ? paths.map(path => {
          return (
            <li key={path.name}>
              <a href={`/${path.path}`}>{path.name}</a>
            </li>
          )
        }) : null}
      </ul>
    </div>
  </>
)

export default Breadcrumb
